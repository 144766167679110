<template>
  <div>
    <section
      v-if="pending"
      class="bg-surface-default-low relative flex h-[calc(100vh-3.4rem)] -translate-y-[3.4rem] flex-col justify-center overflow-hidden px-24 text-center md:h-[calc(100vh-3.7rem)] md:-translate-y-[3.7rem]"
    >
      <div
        class="bg-surface-default-low relative flex flex-col justify-center overflow-hidden"
      >
        <div class="mx-auto w-full max-w-[18rem] px-4 py-24 md:px-6">
          <div
            class="inline-flex w-full flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
          >
            <ul
              class="flex items-center justify-center md:justify-start [&_img]:max-w-none"
              :class="$style.infiniteScroll"
            >
              <li
                v-for="index in 7"
                :key="`eiffelTower-${index}`"
                class="mx-10"
              >
                <RevIllustration
                  :alt="i18n(translations.confirmationTitle)"
                  :height="48"
                  src="/img/recycling/eiffelTower.svg"
                  :width="25"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <h3 class="heading-3 mt-48">{{ i18n(translations.loadingTitle) }}</h3>
      <p class="mt-8">
        {{ i18n(translations.loadingFirstText) }}
      </p>
      <p class="mt-16">
        {{ i18n(translations.loadingSecondText) }}
      </p>
    </section>

    <section v-else data-test="recycling-offer-screen">
      <RevStepper
        :active-step="i18n(translations.stepShippingLabel)"
        :alternative-text-back="i18n(translations.previousPage)"
        :alternative-text-close="i18n(translations.stepperClose)"
        :alternative-text-completed="i18n(translations.stepperCompleted)"
        :alternative-text-current="i18n(translations.stepperCurrent)"
        class="mb-20 sm:hidden"
        :close-button-label="i18n(translations.closeButtonLabel)"
        :steps="steps"
      />
      <div class="bg-static-info-max mood-cornflower relative">
        <picture class="absolute right-0 top-0">
          <RevIllustrationSource
            :height="255"
            :media="BreakpointQueries[Breakpoint.MD]"
            src="/img/recycling/recyclingOfferHero.svg"
            :width="255"
          />
          <RevIllustration
            :alt="i18n(translations.confirmationTitle)"
            :height="240"
            src="/img/recycling/recyclingOfferHero.svg"
            :width="240"
          />
        </picture>
        <RevContainer class="items-center py-12 sm:py-48 md:min-h-280">
          <div class="md:max-w-400">
            <h1 class="heading-1 mt-[180px] md:mt-0">
              {{ i18n(translations.confirmationTitle) }}
            </h1>
            <p class="body-1 mt-8 whitespace-pre-wrap">
              {{
                i18n(
                  translations[
                    data?.shippingLabelUrl
                      ? 'confirmationDescription'
                      : 'confirmationShortDescription'
                  ],
                )
              }}
            </p>
          </div>
          <RevButton
            v-if="data?.shippingLabelUrl"
            :key="i18n(translations.confirmationPrintButtonTitle) + 'desktop'"
            class="mood-main mt-8 max-sm:hidden sm:mt-24"
            full-width="responsive"
            target="_blank"
            :to="data.shippingLabelUrl"
            variant="primary"
            @click="trackShippingLabelNavigation"
          >
            {{ i18n(translations.confirmationPrintButtonTitle) }}
          </RevButton>
          <RevButton
            v-if="data?.shippingLabelUrl"
            :key="i18n(translations.confirmationPrintButtonTitle) + 'mobile'"
            class="mood-main mt-8 sm:mt-24 sm:hidden"
            full-width="always"
            :to="data.shippingLabelUrl"
            variant="secondary"
            @click="trackShippingLabelNavigation"
          >
            {{ i18n(translations.confirmationPrintButtonTitle) }}
          </RevButton>
        </RevContainer>
      </div>
      <RevContainer class="m-auto py-12 sm:py-48">
        <h2 class="heading-2">
          {{ i18n(translations.confirmationNextStepsTitle) }}
        </h2>
        <RevTextList class="mt-24" variant="ordered">
          <template #default>
            <RevTextListItem key="0" class="flex">
              <div>
                <p class="body-1-bold">
                  {{ i18n(translations.confirmationNextStepsDataTitle) }}
                </p>
                <p>
                  {{ i18n(translations.confirmationNextStepsDataDescription) }}
                </p>
                <RevDrawer
                  :close-button-label="i18n(translations.closeButtonLabel)"
                  name="DELETE_YOUR_DATA"
                  :title="i18n(translations.deleteDataNavigationTitle)"
                >
                  <template #body>
                    <div class="grid">
                      <h3 class="heading-3">
                        {{ i18n(translations.deleteDataTitle) }}
                      </h3>
                      <p class="body-1 mt-16">
                        {{ i18n(translations.deleteDataDescription) }}
                      </p>
                      <RevLink
                        v-if="buybackConfig?.recyclingFaq?.backup"
                        target="_blank"
                        :to="buybackConfig.recyclingFaq.backup"
                        >{{
                          i18n(translations.deleteDataBackupLinkTitle)
                        }}</RevLink
                      >
                      <p class="body-1 mt-16">
                        {{ i18n(translations.deleteDataSubtitle) }}
                      </p>
                      <RevLink
                        v-if="buybackConfig?.recyclingFaq?.icloud"
                        target="_blank"
                        :to="buybackConfig.recyclingFaq.icloud"
                        >{{
                          i18n(translations.deleteDataIcloudLinkTitle)
                        }}</RevLink
                      >
                      <RevLink
                        v-if="buybackConfig?.recyclingFaq?.google"
                        target="_blank"
                        :to="buybackConfig.recyclingFaq.google"
                        >{{
                          i18n(translations.deleteDataGoogleLinkTitle)
                        }}</RevLink
                      >
                    </div>
                  </template>
                  <template #trigger="{ open }">
                    <RevLink class="body-1-bold" @click="open">{{
                      i18n(translations.confirmationNextStepsDataLink)
                    }}</RevLink>
                  </template>
                </RevDrawer>
              </div>
            </RevTextListItem>
            <RevTextListItem key="1" class="flex">
              <div>
                <p class="body-1-bold">
                  {{ i18n(translations.confirmationNextStepsPackTitle) }}
                </p>
                <p class="whitespace-pre-wrap">
                  {{ i18n(translations.confirmationNextStepsPackDescription) }}
                </p>
              </div>
            </RevTextListItem>
            <RevTextListItem key="2" class="flex">
              <div>
                <p class="body-1-bold">
                  {{ i18n(translations.confirmationNextStepsShipTitle) }}
                </p>
                <FormattedMessage
                  class="body-1 mt-12"
                  :definition="
                    translations.confirmationNextStepsShipDescription
                  "
                >
                  <template #dropOffLink>
                    <RevLink
                      v-if="buybackConfig?.recyclingFaq?.dropOff"
                      target="_blank"
                      :to="buybackConfig.recyclingFaq?.dropOff"
                    >
                      {{ i18n(translations.confirmationNextStepsDropOff) }}
                    </RevLink>
                  </template>
                  <template #laPosteLink>
                    <RevLink
                      v-if="buybackConfig?.recyclingFaq?.laPoste"
                      target="_blank"
                      :to="buybackConfig.recyclingFaq.laPoste"
                    >
                      {{ i18n(translations.confirmationNextStepsLaPoste) }}
                    </RevLink>
                  </template>
                </FormattedMessage>
              </div>
            </RevTextListItem>
          </template>
        </RevTextList>
        <RevButton
          class="mt-44 sm:hidden"
          full-width="always"
          to="/"
          variant="primary"
          @click="trackHomeNavigation"
        >
          {{ i18n(translations.confirmationCloseCta) }}
        </RevButton>
        <MobileAppBanner class="mt-48" />
      </RevContainer>
    </section>
  </div>
</template>

<script lang="ts" setup>
import { createError, useAsyncData, useRoute } from '#imports'
import { onMounted } from 'vue'

import {
  createRecycling,
  getRecycling,
} from '@backmarket/http-api/src/api-specs-recycling/recycling'
import { useBuybackConfig } from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import {
  Breakpoint,
  BreakpointQueries,
} from '@backmarket/utils/dom/getCurrentBreakpoint'
import { RevButton } from '@ds/components/Button'
import { RevContainer } from '@ds/components/Container'
import { RevDrawer } from '@ds/components/Drawer'
import { RevIllustration } from '@ds/components/Illustration'
import { RevIllustrationSource } from '@ds/components/IllustrationSource'
import { RevLink } from '@ds/components/Link'
import { RevStepper } from '@ds/components/Stepper'
import { RevTextList } from '@ds/components/TextList'
import { RevTextListItem } from '@ds/components/TextListItem'

import MobileAppBanner from '~/scopes/buyback/components/MobileAppBanner/MobileAppBanner.vue'

import translations from './Recycling.translations'

const route = useRoute()

const buybackConfig = useBuybackConfig()
const { trackClick, trackRecyclingProduct, trackRecyclingOrder } = useTracking()
const i18n = useI18n()
const {
  market: { countryCode: marketCode },
} = useMarketplace()

const RECYCLING_CATEGORIES = {
  smartphone: 'SMARTPHONE',
}

const category =
  typeof route.params.deviceCategory === 'string'
    ? RECYCLING_CATEGORIES[
        route.params.deviceCategory as keyof typeof RECYCLING_CATEGORIES
      ]
    : ''

const createRecyclingPromise = $httpFetch(createRecycling, {
  body: {
    marketCode,
    category,
  },
})

const timeoutPromise = new Promise((resolve) => {
  setTimeout(resolve, 4000)
})

const { data, error, pending } = await useAsyncData(
  'create-and-get-recycling',
  async () => {
    const [{ orderId }] = await Promise.all([
      createRecyclingPromise,
      timeoutPromise,
    ])

    return await $httpFetch(getRecycling, {
      pathParams: { orderId },
    })
  },
  {
    server: false,
    lazy: true,
  },
)

onMounted(() => {
  trackRecyclingProduct({
    product_category: category.toLocaleLowerCase(),
  })
  trackRecyclingOrder({
    // Hard coded quantity number until dedicated page is added
    product_quantity: 1,
  })
})

function trackShippingLabelNavigation() {
  trackClick({
    name: 'print_shipping_label',
    zone: 'recycling',
  })
}

function trackHomeNavigation() {
  trackClick({
    name: 'done_and_dusted',
    zone: 'recycling',
  })
}

const steps = [
  i18n(translations.categoryNavigationTitle),
  i18n(translations.stepDevice),
  i18n(translations.stepShippingLabel),
].map((item) => ({
  name: item,
  label: item,
}))

if (error.value) {
  throw createError({
    statusCode: error.value.statusCode,
    statusMessage: error.value.message,
    fatal: true,
  })
}
</script>

<style module>
.infiniteScroll {
  animation: infinite-scroll 0.5s linear infinite;
}

@keyframes infinite-scroll {
  0% {
    transform: translateX(calc(-100% / 7));
  }

  100% {
    transform: translateX(0);
  }
}
</style>
